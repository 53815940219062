import { Box, Text } from '@bfa/common-ui';
import React, { FC, useEffect } from 'react';

import { logAnalyticsEvent } from '../../../../../utils/analytics';
import { ErrorBannerContainerStyled } from '../Banner.styled';

type ReferralGiftUnavailableBannerProps = {
  unavailableState: string;
};

const ReferralGiftUnavailableBanner: FC<ReferralGiftUnavailableBannerProps> = ({
  unavailableState,
}) => {
  useEffect(() => {
    logAnalyticsEvent({
      name: 'SHIPMENT_WEEK_CAMPAIGN',
      label: 'Viewed Referral Gift Unavailable Banner',
    });
    // TODO: igwApiClient.post('/referralGift/recordUnavailableBannerShown');
  }, []);

  let message = '';
  switch (unavailableState) {
    case 'EXPIRED':
      message = 'Sorry, this promotion has ended.';
      break;
    case 'OUT_OF_INVENTORY':
      message = 'Sorry, we are all out of free gifts.';
      break;
    case 'INELIGIBLE':
      message = 'Sorry, you are not eligible to receive this offer.';
      break;
    default:
      console.warn(
        `[ReferralGiftUnavailableBanner] No message specified for state '${unavailableState}'.`
      );
      break;
  }

  return (
    <ErrorBannerContainerStyled textAlign="center">
      <Box role="alert">
        <Text
          as="span"
          className="icon-ipsy-error"
          aria-hidden="true"
          marginRight="2rem"
        />
        <Text as="span">{message}</Text>
      </Box>
    </ErrorBannerContainerStyled>
  );
};

export default ReferralGiftUnavailableBanner;
