import { Column, H5, H6, Hidden, Row, Visible } from '@bfa/common-ui';
import { cookieService } from '@bfa/nextjs-common/services';
import { externalRedirect } from '@bfa/nextjs-common/utils';
import React, { FC, useEffect } from 'react';

import { AnalyticsLink } from '../../../../../components/Analytics';
import { ABSOLUTE_URLS } from '../../../../../constants';
import { logAnalyticsEvent } from '../../../../../utils/analytics';
import CookieName from '../../../../../utils/cookies/cookieName';
import {
  BannerButtonStyled,
  GrayDarkBannerContainerStyled,
} from '../Banner.styled';

type FreeBagIneligibleBannerProps = {
  isCanceled: boolean;
};

const FreeBagIneligibleBanner: FC<FreeBagIneligibleBannerProps> = ({
  isCanceled,
}) => {
  const promotionText =
    'This promotion is valid for first-time subscribers only.';

  useEffect(() => {
    cookieService.setCookie(
      null,
      CookieName.FREE_BAG_INVALID_BANNER_COOKIE,
      'seen',
      {}
    );
    logAnalyticsEvent({
      name: 'FREE_BAGS',
      label: 'Landed on Promotion Not Valid Banner',
    });
  }, []);

  return (
    <AnalyticsLink
      name="CHOOSE_SUB_PACKAGE"
      label="Clicked a subscribe button on site"
      href={ABSOLUTE_URLS.SUB_CHOOSE_PAGE}
    >
      <GrayDarkBannerContainerStyled textAlign="center">
        <Hidden mobile>
          <Row justifyContent="center" alignItems="center">
            <Column>
              <H5 as="div">{promotionText}</H5>
            </Column>
            {isCanceled && (
              <BannerButtonStyled
                onClick={() => externalRedirect(ABSOLUTE_URLS.SUB_CHOOSE_PAGE)}
                secondary
                main
              >
                Join Now
              </BannerButtonStyled>
            )}
          </Row>
        </Hidden>
        <Visible mobile>
          <H6 as="div">
            {promotionText}
            {isCanceled && ' Good news though – you can still re-subscribe.'}
          </H6>
        </Visible>
      </GrayDarkBannerContainerStyled>
    </AnalyticsLink>
  );
};

export default FreeBagIneligibleBanner;
