import { logWarn } from '@bfa/nextjs-common/utils';
import React, { FC } from 'react';

import { BannerTypes } from '../../types';
import CreatorsLandingPageBanner from './CreatorsLandingPageBanner';
import FreeBagActiveBanner from './FreeBagActiveBanner';
import FreeBagExpiredBanner from './FreeBagExpiredBanner';
import FreeBagIneligibleBanner from './FreeBagIneligibleBanner';
import GiftExpiredBanner from './GiftExpiredBanner';
import OpenSubsReferralBanner from './OpenSubsReferralBanner';
import ReferralGiftAvailableBanner from './ReferralGiftAvailableBanner';
import ReferralGiftUnavailableBanner from './ReferralGiftUnavailableBanner';
import ReferralWithProfileBanner from './ReferralWithProfileBanner';
import TeaserQuestionBanner from './TeaserQuestionBanner';

type BannerProps = {
  name: string;
  properties?: Record<string, any>;
};

const Banner: FC<BannerProps> = (props) => {
  const { name, properties }: BannerProps & { properties?: any } = props;

  switch (name) {
    case BannerTypes.CREATORS_LANDING_PAGE_BANNER:
      return <CreatorsLandingPageBanner {...properties} />;
    case BannerTypes.FREE_BAG_INVITED_USER_ACTIVE_BANNER:
      return <FreeBagActiveBanner {...properties} />;
    case BannerTypes.FREE_BAG_INVITED_USER_INELIGIBLE_BANNER:
      return <FreeBagIneligibleBanner {...properties} />;
    case BannerTypes.FREE_BAG_INVITED_USER_EXPIRED_BANNER:
      return <FreeBagExpiredBanner {...properties} />;
    case BannerTypes.GIFT_WITH_SUBSCRIPTION_EXPIRED_BANNER:
      return <GiftExpiredBanner {...properties} />;
    case BannerTypes.OPEN_SUBS_REFERRAL_BANNER:
      return <OpenSubsReferralBanner {...properties} />;
    case BannerTypes.REFERRAL_GIFT_AVAILABLE_BANNER:
      return <ReferralGiftAvailableBanner {...properties} />;
    case BannerTypes.REFERRAL_GIFT_UNAVAILABLE_BANNER:
      return <ReferralGiftUnavailableBanner {...properties} />;
    case BannerTypes.FREEBAG_STYLE_REFERRAL_BANNER:
      return <ReferralWithProfileBanner {...properties} />;
    case BannerTypes.SEO_TEASER_QUESTION_BANNER:
      return <TeaserQuestionBanner {...properties} />;
    case BannerTypes.REFERRAL_BANNER:
      return null;

    default:
      logWarn(`No banner component found for type '${name}'.`);
      return null;
  }
};

export default Banner;
