import { Column, H6, Row } from '@bfa/common-ui';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { GrayDarkBannerContainerStyled } from '../Banner.styled';

const BannerButtonStyled = styled.button`
  background-color: transparent !important;
  border: 0 !important;

  &:hover {
    background-color: transparent !important;
  }
`;

const GiftExpiredBanner: FC = () => {
  const [hidden, setHidden] = useState(false);

  if (hidden) {
    return null;
  }

  return (
    <GrayDarkBannerContainerStyled textAlign="center">
      <Row>
        <Column flex="1">
          <H6 as="div">
            Sorry, we are out of our free bonus gift. The good news? You can
            still subscribe and discover 5 must-have beauty products every
            month!
          </H6>
        </Column>
        <Column>
          <BannerButtonStyled
            aria-label="Close"
            className="icon-ipsy-close-cross"
            onClick={() => setHidden(true)}
          />
        </Column>
      </Row>
    </GrayDarkBannerContainerStyled>
  );
};

export default GiftExpiredBanner;
