import { Column, Row, Text, Visible } from '@bfa/common-ui';
import { externalRedirect } from '@bfa/nextjs-common/utils';
import React, { FC } from 'react';

import { AnalyticsLink } from '../../../../../components/Analytics';
import { ABSOLUTE_URLS } from '../../../../../constants';
import { AnalyticsEventKey } from '../../../../../utils/analytics/constants';
import {
  BannerButtonStyled,
  NavyBannerContainerStyled,
} from '../Banner.styled';

type OpenSubsReferralBannerProps = {
  bannerCopy: string;
  isLoggedIn: boolean;
  hasEverSubscribed: boolean;
};

const OpenSubsReferralBanner: FC<OpenSubsReferralBannerProps> = ({
  bannerCopy,
  hasEverSubscribed,
  isLoggedIn,
}) => {
  let eventKey: AnalyticsEventKey;
  let eventLabel: string;
  let href: string;

  if (isLoggedIn) {
    if (!hasEverSubscribed) {
      eventKey = 'CHOOSE_SUB_PACKAGE';
      eventLabel = 'Clicked a subscribe button on site';
      href = ABSOLUTE_URLS.SUBSCRIBE;
    } else {
      eventKey = 'REACTIVATE';
      eventLabel = 'Clicked a reactivate button on site';
      href = ABSOLUTE_URLS.REACTIVATE;
    }
  } else {
    eventKey = 'CHOOSE_SUB_PACKAGE';
    eventLabel = 'Clicked a subscribe button on site';
    href = ABSOLUTE_URLS.START_QUIZ;
  }

  return (
    <AnalyticsLink name={eventKey} label={eventLabel} href={href} passHref>
      <NavyBannerContainerStyled textAlign="center" as="a">
        <Row justifyContent="center" alignItems="center">
          <Column>
            <Text>{bannerCopy}</Text>
          </Column>
          <Visible tablet desktop>
            <BannerButtonStyled
              onClick={() => externalRedirect(href)}
              secondary
              main
              text="Join Now"
            />
          </Visible>
        </Row>
      </NavyBannerContainerStyled>
    </AnalyticsLink>
  );
};

export default OpenSubsReferralBanner;
