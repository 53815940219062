/**
 * Pages where banners can be displayed.
 *
 * Please keep this file in sync with `BannerLocations.groovy` in `ipsy-grails-web`.
 */
export enum BannerLocations {
  ROOT_BANNER = 'ROOT_BANNER', // Displayed across all pages
  ADMIN_BANNER = 'ADMIN_BANNER', // Displayed where specified

  SIGNED_OUT_HOMEPAGE = 'SIGNED_OUT_HOMEPAGE',

  LEARN_MORE_PAGE = 'LEARN_MORE_PAGE',
  PLANS_PAGE = 'PLANS_PAGE',
  BROWSE_PAGE = 'BROWSE_PAGE',
  BLOG_ARTICLE_PAGE = 'BLOG_ARTICLE_PAGE',

  BEAUTY_QUIZ_PAGE = 'MAIN_QUIZ_TOP',
  CHOOSE_PACKAGE_PAGE = 'CHOOSE_PACKAGE_PAGE',
  SUBSCRIBE_PAGE = 'SUBSCRIBE_PAGE',
  REACTIVATE_PAGE = 'REACTIVATE_PAGE',

  GLAMBAG_PAGE = 'GLAMBAG_PAGE',

  PRODUCT_PAGE = 'PRODUCT_PAGE',
}

export enum BannerTypes {
  ADMIN_LOGIN_AS_BANNER = 'ADMIN_LOGIN_AS_BANNER',
  ANNUAL_BANNER = 'ANNUAL_BANNER',
  BROWSE_PAGE_BANNER = 'BROWSE_PAGE_BANNER',
  CREATORS_LANDING_PAGE_BANNER = 'CREATORS_LANDING_PAGE_BANNER',
  FREE_BAG_INVITED_USER_ACTIVE_BANNER = 'FREE_BAG_INVITED_USER_ACTIVE_BANNER',
  FREE_BAG_INVITED_USER_INELIGIBLE_BANNER = 'FREE_BAG_INVITED_USER_INELIGIBLE_BANNER',
  FREE_BAG_INVITED_USER_EXPIRED_BANNER = 'FREE_BAG_INVITED_USER_EXPIRED_BANNER',
  FULL_SIZE_PROMOTION_BANNER = 'FULL_SIZE_PROMOTION_BANNER',
  GIFT_WITH_SUBSCRIPTION_BANNER = 'GIFT_WITH_SUBSCRIPTION_BANNER',
  GIFT_WITH_SUBSCRIPTION_EXPIRED_BANNER = 'GIFT_WITH_SUBSCRIPTION_EXPIRED_BANNER',
  GIFT_WITH_SUBSCRIPTION_GIFT_CHOICE_BANNER = 'GIFT_WITH_SUBSCRIPTION_GIFT_CHOICE_BANNER',
  GLAM_BAG_PROMOTION_BANNER = 'GLAM_BAG_PROMOTION_BANNER',
  GLAM_BAG_PRODUCT_REVIEW_BANNER = 'GLAM_BAG_PRODUCT_REVIEW_BANNER',
  PAYMENT_DECLINE_BANNER = 'PAYMENT_DECLINE_BANNER',
  IOS_SMART_BANNER = 'IOS_SMART_BANNER',
  MONTHLY_DELIGHT_CAMPAIGN_BANNER = 'MONTHLY_DELIGHT_CAMPAIGN_BANNER',
  NON_US_PLANS_BANNER = 'NON_US_PLANS_BANNER',
  NOT_ELIGIBLE_SIGNED_IN_GIFT_WITH_SUBSCRIPTION_BANNER = 'NOT_ELIGIBLE_SIGNED_IN_GIFT_WITH_SUBSCRIPTION_BANNER',
  NOT_REDEEMED_GIFT_WITH_SUBSCRIPTION_BANNER = 'NOT_REDEEMED_GIFT_WITH_SUBSCRIPTION_BANNER',
  OPEN_SUBS_BANNER = 'OPEN_SUBS_BANNER',
  OPEN_SUBS_REFERRAL_BANNER = 'OPEN_SUBS_REFERRAL_BANNER',
  PRODUCT_CHOICE_CLOSED = 'PRODUCT_CHOICE_CLOSED',
  PROGRAM_PROMOTION_BANNER = 'PROGRAM_PROMOTION_BANNER',
  POINT_SHARE_BANNER = 'POINT_SHARE_BANNER',
  QUIZ_GIFT_WITH_SUBSCRIPTION_BANNER = 'QUIZ_GIFT_WITH_SUBSCRIPTION_BANNER',
  REACT_PLACEHOLDER_BANNER = 'REACT_PLACEHOLDER_BANNER',
  REFERRAL_BANNER = 'REFERRAL_BANNER',
  REFERRAL_GIFT_EXPIRED_BANNER = 'REFERRAL_GIFT_EXPIRED_BANNER',
  REFERRAL_GIFT_AVAILABLE_BANNER = 'REFERRAL_GIFT_AVAILABLE_BANNER',
  REFERRAL_GIFT_UNAVAILABLE_BANNER = 'REFERRAL_GIFT_UNAVAILABLE_BANNER',
  SEO_TEASER_QUESTION_BANNER = 'SEO_TEASER_QUESTION_BANNER',
  SHOPPER_PROMOTION_BANNER = 'SHOPPER_PROMOTION_BANNER',
  SKIP_WAITLIST_BANNER = 'SKIP_WAITLIST_BANNER',
  SKIP_WAITLIST_GIFT_WITH_SUBSCRIPTION_BANNER = 'SKIP_WAITLIST_GIFT_WITH_SUBSCRIPTION_BANNER',
  STICKY_BANNER = 'STICKY_BANNER',
  FREEBAG_STYLE_REFERRAL_BANNER = 'FREEBAG_STYLE_REFERRAL_BANNER',
}
