import { Column, Row, Text } from '@bfa/common-ui';
import React, { FC } from 'react';

import { NavyBannerContainerStyled } from '../Banner.styled';

type ReferralGiftAvailableBannerProps = {
  giftWithReferralDesc: string;
};

const ReferralGiftAvailableBanner: FC<ReferralGiftAvailableBannerProps> = ({
  giftWithReferralDesc,
}) => {
  return (
    <div id="referral-gift-eligible-banner">
      <NavyBannerContainerStyled>
        <Row alignItems="center" justifyContent="center">
          <Column>
            <Text bold py="1rem">
              {giftWithReferralDesc}
            </Text>
          </Column>
        </Row>
      </NavyBannerContainerStyled>
    </div>
  );
};

export default ReferralGiftAvailableBanner;
