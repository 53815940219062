import { Box, Flex, NextImg, Text } from '@bfa/common-ui';
import React, { FC, useEffect } from 'react';

import { AnalyticsLink } from '../../../../../components/Analytics';
import { ABSOLUTE_URLS } from '../../../../../constants';
import { logAnalyticsEvent } from '../../../../../utils/analytics';
import {
  BannerCircleImageStyled,
  GrayDarkestBannerContainerStyled,
} from '../Banner.styled';

type ReferralWithProfileBannerProps = {
  invitingUserFirstName: string;
  invitingUserProfilePic: string;
};

const ReferralWithProfileBanner: FC<ReferralWithProfileBannerProps> = ({
  invitingUserFirstName,
  invitingUserProfilePic,
}) => {
  useEffect(() => {
    logAnalyticsEvent({
      name: 'REFERRAL_BANNER',
      label: 'View Freebag Style Referral Banner',
    });
  }, []);

  return (
    <AnalyticsLink
      name="REFERRAL_BANNER"
      label="Clicked Freebag Style Referral Banner"
      href={ABSOLUTE_URLS.START_QUIZ}
      passHref
    >
      <GrayDarkestBannerContainerStyled as="a">
        <Flex alignItems="center" justifyContent="center">
          <Box marginLeft="0.5rem" marginRight="1.5rem">
            <BannerCircleImageStyled>
              <NextImg
                images={[
                  {
                    src: invitingUserProfilePic || '',
                    width: 46,
                    height: 46,
                    alt: `You got a referral from ${invitingUserFirstName}!`,
                  },
                ]}
              />
            </BannerCircleImageStyled>
          </Box>
          <Text fontWeight="700" marginLeft="1.5rem">
            Your friend {invitingUserFirstName} loves IPSY and wants you to
            join.
          </Text>
        </Flex>
      </GrayDarkestBannerContainerStyled>
    </AnalyticsLink>
  );
};

export default ReferralWithProfileBanner;
