import { H5 } from '@bfa/common-ui';
import { cookieService } from '@bfa/nextjs-common/services';
import React, { FC, useEffect } from 'react';

import { AnalyticsLink } from '../../../../../components/Analytics';
import { ABSOLUTE_URLS } from '../../../../../constants';
import { logAnalyticsEvent } from '../../../../../utils/analytics';
import CookieName from '../../../../../utils/cookies/cookieName';
import { GrayDarkBannerContainerStyled } from '../Banner.styled';

type FreeBagExpiredBannerProps = {
  isClickable: boolean;
  isLoggedIn: boolean;
};

const FreeBagExpiredBanner: FC<FreeBagExpiredBannerProps> = ({
  isClickable,
  isLoggedIn,
}) => {
  useEffect(() => {
    cookieService.setCookie(
      null,
      CookieName.FREE_BAG_INVALID_BANNER_COOKIE,
      'seen',
      {}
    );
    logAnalyticsEvent({
      name: 'FREE_BAGS',
      label: 'Landed on Promotion Not Valid Banner',
    });
  }, []);

  const inviteExpired = (
    <GrayDarkBannerContainerStyled
      textAlign="center"
      as={isClickable ? 'a' : 'div'}
    >
      <H5 as="div">
        Unfortunately your invite has expired but you can still subscribe to the
        ipsy Glam Bag.
      </H5>
    </GrayDarkBannerContainerStyled>
  );

  return isClickable ? (
    <AnalyticsLink
      name="FREE_BAGS"
      label="Invite Expired Error on Signed Out Page"
      href={
        isLoggedIn ? ABSOLUTE_URLS.SUB_CHOOSE_PAGE : ABSOLUTE_URLS.START_QUIZ
      }
      passHref
    >
      {inviteExpired}
    </AnalyticsLink>
  ) : (
    inviteExpired
  );
};

export default FreeBagExpiredBanner;
