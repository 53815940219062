import { Box, breakpoint, Button, color, Flex, Text } from '@bfa/common-ui';
import { analyticsService } from '@bfa/nextjs-common/services';
import Router from 'next/router';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ABSOLUTE_URLS } from '../../../../../constants';
import { logAnalyticsEvent } from '../../../../../utils/analytics';

export const BannerContainerStyled = styled(Flex)`
  background-color: ${color('beige')};
  -webkit-font-smoothing: antialiased;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const AnswerContainerStyled = styled(Button)`
  background-color: ${color('white')};
  color: ${color('black')};
  @include box-sizing(border-box);
  min-width: 10.8rem;
  height: 7.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 7.2rem;
  margin-right: 1rem;
  white-space: nowrap;
  border: 0.1rem solid ${color('grayMedium')};
  @include transition(border-color 0.5s ease-out);

  &:hover,
  &:focus {
    color: ${color('black')};
    border-color: ${color('pinkIpsy')};
  }

  &:active {
    color: ${color('pinkAccessible')};
    background-color: rgba(${color('pinkIpsy')}, 0.2);
  }

  @media screen and (min-width: ${breakpoint('md')}) {
    min-width: 12.5rem;
    height: 7.5rem;
    line-height: 7.5rem;
  }
`;

export const CloseButtonStyled = styled(Button)`
  @include font-size(1.6rem);
  background-color: transparent;
  color: black;
  border: none;

  &:hover {
    color: black;
    opacity: 0.5;
  }
`;

export const AnswersContainerStyled = styled(Flex)`
  -ms-overflow-style: none;
  scrollbar-width: none;
  white-space: nowrap;
`;

export type TeaserQuestion = {
  id: string;
  content: string;
  answers: string[];
};

type PropsType = {
  model: {
    teaserQuestion: TeaserQuestion;
    redirectUrl: string;
  };
};

const EVENT_KEY = 'PRODUCT_PAGE_TEASER_QUESTION';
let isRenderEventLogged = false;

const TeaserQuestionBanner: FC<PropsType> = ({ model }) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (model && !isRenderEventLogged) {
      isRenderEventLogged = true;
      logAnalyticsEvent({
        name: EVENT_KEY,
        label: `Viewed Teaser Question ${model.teaserQuestion.id}`,
      });
    }
  }, [model]);

  const hide = (): void => {
    setOpen(false);

    logAnalyticsEvent({
      name: EVENT_KEY,
      label: 'Dismissed Teaser Question',
    });
  };

  const clickAnswer = (event: any): void => {
    event.preventDefault();
    const { teaserQuestion, redirectUrl } = model;
    const deferredEvents = [
      {
        name: EVENT_KEY,
        label: `Clicked Teaser Question ${teaserQuestion.id}`,
        category: 'Product Page',
        action: 'Teaser Question',
      },
    ];

    const url = redirectUrl || ABSOLUTE_URLS.QUIZ;
    const deferredUrl = analyticsService.buildDeferredEventsUrl(
      url,
      deferredEvents
    );

    Router.push(`${deferredUrl}&fromTeaserQuestion=true`);
  };

  if (!open || !model) {
    return null;
  }

  return (
    <BannerContainerStyled
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      paddingBottom="2rem"
    >
      <Flex alignSelf="flex-end">
        <CloseButtonStyled
          aria-label="close"
          data-testid="close"
          className="icon-ipsy-close-cross"
          onClick={hide}
        />
      </Flex>
      <Text large textAlign="center" marginBottom="20px" bold>
        {model.teaserQuestion.content}
      </Text>
      <AnswersContainerStyled
        marginBottom="1rem"
        overflowX="auto"
        maxWidth={['35rem', '65rem', '85rem', '100rem']}
      >
        {model.teaserQuestion.answers.map((answer) => (
          <Box display="inline-block" key={answer}>
            <AnswerContainerStyled onClick={clickAnswer}>
              <Text bold large>
                {answer}
              </Text>
            </AnswerContainerStyled>
          </Box>
        ))}
      </AnswersContainerStyled>
      <Text textAlign="center" small>
        This will help us personalize your experience.
      </Text>
    </BannerContainerStyled>
  );
};

export default TeaserQuestionBanner;
